import React from 'react';

function Video({ videoId, videoTitle }) {
  const videoSrc = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className="max-w-6xl mx-auto m-0 p-5">
      <div className="relative" style={{ paddingBottom: "56.25%" }}>
        <iframe
          className="absolute top-0 left-0 w-full h-full"
          src={videoSrc}
          title={videoTitle || 'YouTube video player'}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

export default Video;
