import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

function Intro() {

  return (
    <>

      <div style={{ background: '#CBD5E1', marginTop: '-1px' }}>

        <div className="w-full flex p-5 pt-40 text-center text-blue-800" style={{ opacity: .75 }}>
          <h1 className="lg:text-5xl md:text-4xl text-3xl ml-auto mr-auto">Andrew Peterson</h1>
        </div>

        <div className="w-full flex p-5 text-center text-blue-400" style={{ opacity: .75 }}>
          <h2 className="md:text-2xl text-xl ml-auto mr-auto">Developer</h2>
        </div>

        <BrowserView>
          <div className="flex w-full relative">
{/* 
            <div className="absolute bottom-0 left-0 flex  h-full top-24 w-1/2 2xl:w-2/3">
              <img src="./plant_1.svg" alt="plant" className="lg:block h-3/4 m-auto" />
              <img src="./plant_2.svg" alt="plant" className="hidden xl:flex h-3/4 m-auto" />
              <img src="./plant_3.svg" alt="plant" className="hidden 3xl:flex h-3/4 m-auto" />
              <img src="./plant_4.svg" alt="plant" className="hidden 4xl:flex h-3/4 m-auto" />
              <img src="./plant_5.svg" alt="plant" className="hidden 5xl:flex h-3/4 m-auto" />
            </div> */}

            <div className="w-full h-100 relative" />

            <img src="./portrait.svg" alt="portrait" className="relative top-28 lg:m-auto m-0 max-w-6xl" />

          </div>
        </BrowserView>

        <MobileView>
          <img src="./portrait.svg" alt="portrait" className="relative top-12 lg:m-auto m-0 max-w-6xl" />
        </MobileView>

      </div>

      <div className="bg-blue-700" style={{ marginTop: '-2px', height: '3px' }} />

    </>
  );
}

export default Intro;
