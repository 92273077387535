function Header() {
  const sendMail = () => {
    const mailto = "mailto:peterson8000@yahoo.com";
    window.open(mailto, '_blank');
  }
  return (
    <div className="w-full flex m-auto bg-blue-300">
      <div className="w-1/2 px-10 flex">
        <img src="" className="h-10 mb-auto mt-auto"/>
      </div>
      <div className="w-1/2 text-right p-5">
        <button className="opacity-50 hover:opacity-100 bg-transparent hover:bg-blue-500 text-blue-900 font-semibold hover:text-white py-2 px-4 border-2 border-blue-500 hover:border-transparent rounded-full" onClick={sendMail}>Say Hello</button>
      </div>
    </div>
  );
}

export default Header;
