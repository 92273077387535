function Description(props) {
  const sendMail = () => {
    const mailto = "mailto:peterson8000@yahoo.com";
    window.open(mailto, '_blank');
  }
  const { heading, content } = props;
  return (

    <div className="w-full px-5 pb-12 pt-32 text-white">
      <div className="w-full py-5 max-w-6xl m-auto">
        <h3 className="text-3xl px-5">About Me</h3>
      </div>
      <div className="w-full m-auto max-w-6xl">
        <p className="text-xl mb-10 px-5">
          Developer and enthusiastic problem-solver. I value clear communication, thoughtful design, and readable code. I enjoy building from scratch, but am equally comfortable contributing to existing projects.
        </p>
        <p className="text-xl mb-10 px-5">
          Currently, I'm coding for <a className="hover:underline" href="https://www.gartrellgroup.com/" target="_blank">The Gartrell Group</a>.
        </p>
      </div>
    </div>



  );
}

export default Description;
