function Columns() {
  // const goTo = (link) => {
  //   window.open(link, '_blank');
  // }
  return (
    <div className="bg-blue-700" style={{marginTop:'-50px'}}>
      <div className="grid grid-cols-1 md:grid-cols-3 p-5 m-auto xl:w-4/5 max-w-6xl py-10 pt-20">
        <div className=" z-50 bg-blue-400 border-solid border-2 border-gray-300 p-5 flex md:rounded-tl-lg md:rounded-bl-lg md:rounded-tr-none md:rounded-br-none rounded-tl-lg rounded-tr-lg rounded-bl-none rounded-br-none">
          <div className="m-auto text-center space-y-2  px-5 py-10">
            <img src="./frontend.svg" alt="Frontend" className="m-auto pb-5"/>
            <h2 className="text-2xl text-white">Developer</h2>
            <h3 className="text-xl text-white pt-10">Languages I use:</h3>
            <p className="text-gray-200">Javascript, SQL, Python & more</p>
            <h3 className="text-xl text-white pt-10">Development Tools:</h3>
            <p className="text-gray-200">Front-end frameworks, Open-source libraries, Design Systems, ORMs, Docker containers, Cloud computing, Stack Overflow, Google, Large language models</p>
          </div>
        </div>
        <div className=" z-50  bg-blue-400 border-solid border-2 md:border-r-0 md:border-l-0 border-r-2 border-l-2 border-b-0 border-t-0 md:border-b-2 md:border-t-2 border-gray-300 p-5">
        <div className="m-auto text-center space-y-2 px-5 py-10">
          <img src="./designer.svg" alt="Designer" className="m-auto pb-5"/>
          <h2 className="text-2xl text-white">Designer</h2>
          <h3 className="text-xl text-white pt-10">Things I design:</h3>
          <p className="text-gray-200">Satisfying User Experiences, Beautiful User Interfaces, Interactive Applications</p>
          <h3 className="text-xl text-white pt-10">Design Tools:</h3>
          <p className="text-gray-200">User Stories, Feedback, Pencil, Paper, Sketch, Figma, Adobe XD, Adobe Suite, Affinity Designer</p>
        </div>
        </div>
        <div className=" z-50  bg-blue-400 border-solid border-2 border-gray-300 md:rounded-tr-lg md:rounded-br-lg md:rounded-tl-none md:rounded-bl-none rounded-bl-lg rounded-br-lg rounded-tl-none rounded-tr-none p-5">
        <div className="m-auto text-center space-y-2 px-5 py-10">
          <img src="./mentor.svg" alt="mentor" className="m-auto pb-5"/>
          <h2 className="text-2xl text-white">Solver</h2>
          <h3 className="text-xl text-white pt-10">Problems I solve:</h3>
          <p className="text-gray-200">Clunky User Experiences, Slow load times, Excess Complexity</p>
          <h3 className="text-xl text-white pt-10">Problem-solving Tools:</h3>
          <p className="text-gray-200">First Principles, Relentless Effort, Long Walks, Mentors, Colleagues</p>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Columns;
