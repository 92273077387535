import { ReactComponent as LinkedIn } from '../img/linkedin.svg';
import { ReactComponent as Github } from '../img/github.svg';
import { ReactComponent as Cv } from '../img/cv.svg';
import { ReactComponent as Youtube } from '../img/youtube.svg';

function Outro() {
  const goTo = (link) => {
    window.open(link, '_blank');
  }
  return (
    <div className="bottom-5 sm:relative sm:pt-10 sm:pt-40 sm:pb-40 lg:pb-20 w-full pb-10">
      <div className="sm:w-1/2 w-full justify-center m-auto grid grid-cols-2">

        <div onClick={() => goTo('https://www.linkedin.com/in/ndrwptrsn/')} style={{width:'60px',height:'60px'}} className="group border-2 m-auto flex border-blue-400 hover:bg-blue-400 bg-blue-700 p-3 rounded-full cursor-pointer">
          <LinkedIn className="opacity-100 fill-current text-blue-400 group-hover:text-white"/>
        </div>


        {/* <div onClick={() => goTo('https://github.com/ndrwptrsn')} style={{width:'60px',height:'60px'}} className="group border-2 m-auto flex border-blue-400 hover:bg-blue-400 bg-blue-700  p-3 rounded-full cursor-pointer">
          <Github className="opacity-100 fill-current text-blue-400 group-hover:text-white"/>
        </div> */}


        <div onClick={() => goTo('./AndrewPetersonCV.pdf')} style={{width:'60px',height:'60px'}} className="group border-2 m-auto flex border-blue-400 hover:bg-blue-400 bg-blue-700  p-3 rounded-full cursor-pointer">
          <Cv className="opacity-100 fill-current text-blue-400 group-hover:text-white"/>
        </div>


      </div>
    </div>
  );
}

export default Outro;
