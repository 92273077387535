import Intro from './sections/intro.js';
import Description from './sections/description.js';
import Header from './sections/header.js';
import Columns from './sections/columns.js';
import Outro from './sections/outro.js';
import Video from './sections/video.js';

function App() {

  return (
    <div>

      <Header/>

      <Intro/>

      <Description/>

      <Columns/>

      <Video videoId="uDCu5HSbNFo"/>

      <Video videoId="XcviRSAUCYo"/>

      <Outro/>

    </div>
  );
}

export default App;
